
import Vue from "vue";
import i18n from "@/i18n";
import axios from "@/plugins/axios";
import formatUtil from "../../mixins/roundValues";
import highlightReports from "../../mixins/highlightReports";
import { BleedingDisorder } from "@/models/reports/HemophiliavWDbyAge/bleedingdisorder";
import { DiseaseTypeResult } from "@/models/reports/HemophiliavWDbyAge/hemophiliavWDbyAgeData";
import InformationDDC from "./InformationDDC.vue";
import { formatFinancialAmounts } from "../../utils/format";

export default Vue.extend({
  name: "hemophiliavWDbyAge",
  mixins: [formatUtil, highlightReports],
  components: { InformationDDC },
  data: () => ({
    data: [],
    formatFinancialAmounts,
    agegroups: [],
    bleedingDisorderSelected: [],
    bleedingDisorders: [],
    isLoading: false,
    payload: {
      year: null,
      diseaseType: [] as number[],
    },
  }),
  computed: {
    yearSelected() {
      return this.$store.getters["ddcreport/getYearSelected"].year;
    },
    title() {
      return i18n.t("reports.ddcreports.listReports.hemophiliavWDbyAge");
    },
  },
  methods: {
    getFieldByGroupAge(groupAge: string, diseaseTypeResult: DiseaseTypeResult) {
      switch (groupAge) {
        case "0 to 4": {
          return [
            diseaseTypeResult.current_ZeroToFour,
            diseaseTypeResult.previous_ZeroToFour,
            diseaseTypeResult.zeroToFour_Perc_Diff,

            diseaseTypeResult.current_ZeroToFour_Male,
            diseaseTypeResult.previous_ZeroToFour_Male,
            diseaseTypeResult.zeroToFour_Perc_Diff_Male,

            diseaseTypeResult.current_ZeroToFour_Female,
            diseaseTypeResult.previous_ZeroToFour_Female,
            diseaseTypeResult.zeroToFour_Perc_Diff_Female,

            diseaseTypeResult.current_ZeroToFour_Unknown,
            diseaseTypeResult.previous_ZeroToFour_Unknown,
            diseaseTypeResult.zeroToFour_Perc_Diff_Unknown,
          ];
        }
        case "5 to 13": {
          return [
            diseaseTypeResult.current_FiveToThirteen,
            diseaseTypeResult.previous_FiveToThirteen,
            diseaseTypeResult.fiveToThirteen_Perc_Diff,

            diseaseTypeResult.current_FiveToThirteen_Male,
            diseaseTypeResult.previous_FiveToThirteen_Male,
            diseaseTypeResult.fiveToThirteen_Perc_Diff_Male,

            diseaseTypeResult.current_FiveToThirteen_Female,
            diseaseTypeResult.previous_FiveToThirteen_Female,
            diseaseTypeResult.fiveToThirteen_Perc_Diff_Female,

            diseaseTypeResult.current_FiveToThirteen_Unknown,
            diseaseTypeResult.previous_FiveToThirteen_Unknown,
            diseaseTypeResult.fiveToThirteen_Perc_Diff_Unknown,
          ];
        }
        case "14 to 18": {
          return [
            diseaseTypeResult.current_FourteenToEighteen,
            diseaseTypeResult.previous_FourteenToEighteen,
            diseaseTypeResult.fourteenToEighteen_Perc_Diff,
            
            diseaseTypeResult.current_FourteenToEighteen_Male,
            diseaseTypeResult.previous_FourteenToEighteen_Male,
            diseaseTypeResult.fourteenToEighteen_Perc_Diff_Male,

            diseaseTypeResult.current_FourteenToEighteen_Female,
            diseaseTypeResult.previous_FourteenToEighteen_Female,
            diseaseTypeResult.fourteenToEighteen_Perc_Diff_Female,

            diseaseTypeResult.current_FourteenToEighteen_Unknown,
            diseaseTypeResult.previous_FourteenToEighteen_Unknown,
            diseaseTypeResult.fourteenToEighteen_Perc_Diff_Unknown,
          ];
        }
        case "19 to 44": {
          return [
            diseaseTypeResult.current_NineteenToFourtyFour,
            diseaseTypeResult.previous_NineteenToFourtyFour,
            diseaseTypeResult.nineteenToFourtyFour_Perc_Diff,

            diseaseTypeResult.current_NineteenToFourtyFour_Male,
            diseaseTypeResult.previous_NineteenToFourtyFour_Male,
            diseaseTypeResult.nineteenToFourtyFour_Perc_Diff_Male,

            diseaseTypeResult.current_NineteenToFourtyFour_Female,
            diseaseTypeResult.previous_NineteenToFourtyFour_Female,
            diseaseTypeResult.nineteenToFourtyFour_Perc_Diff_Female,

            diseaseTypeResult.current_NineteenToFourtyFour_Unknown,
            diseaseTypeResult.previous_NineteenToFourtyFour_Unknown,
            diseaseTypeResult.nineteenToFourtyFour_Perc_Diff_Unknown,
          ];
        }
        case "45 or older": {
          return [
            diseaseTypeResult.current_FourtyFiveAndAbove,
            diseaseTypeResult.previous_FourtyFiveAndAbove,
            diseaseTypeResult.fourtyFiveAndAbove_Perc_Diff,

            diseaseTypeResult.current_FourtyFiveAndAbove_Male,
            diseaseTypeResult.previous_FourtyFiveAndAbove_Male,
            diseaseTypeResult.fourtyFiveAndAbove_Perc_Diff_Male,

            diseaseTypeResult.current_FourtyFiveAndAbove_Female,
            diseaseTypeResult.previous_FourtyFiveAndAbove_Female,
            diseaseTypeResult.fourtyFiveAndAbove_Perc_Diff_Female,

            diseaseTypeResult.current_FourtyFiveAndAbove_Unknown,
            diseaseTypeResult.previous_FourtyFiveAndAbove_Unknown,
            diseaseTypeResult.fourtyFiveAndAbove_Perc_Diff_Unknown,
          ];
        }
        case "Unknown": {
          return [
            diseaseTypeResult.current_Unknown,
            diseaseTypeResult.previous_Unknown,
            diseaseTypeResult.unknown_Perc_Diff,

            diseaseTypeResult.current_Unknown_Male,
            diseaseTypeResult.previous_Unknown_Male,
            diseaseTypeResult.unknown_Perc_Diff_Male,

            diseaseTypeResult.current_Unknown_Female,
            diseaseTypeResult.previous_Unknown_Female,
            diseaseTypeResult.unknown_Perc_Diff_Female,

            diseaseTypeResult.current_Unknown_Unknown,
            diseaseTypeResult.previous_Unknown_Unknown,
            diseaseTypeResult.unknown_Perc_Diff_Unknown,
          ];
        }
      }
    },
    loadAgeGroup() {
      axios
        .get("Report/DDC/DiseaseAge/AgeGroup")
        .then((res) => {
          this.agegroups = res.data;
        })
        .catch((err) => console.log(err));
    },
    loadFilters() {
      axios
        .get(`Report/DDC/DiseaseGender/filter`)
        .then((res) => {
          this.bleedingDisorders = res.data;
        })
        .catch((err) => console.log(err));
    },
    getData() {
      this.isLoading = true;
      const arrayIDDisorders: number[] = this.bleedingDisorderSelected.map(
        function (item: BleedingDisorder) {
          return item.id;
        }
      );
      this.payload.diseaseType = arrayIDDisorders;
      this.payload.year = this.yearSelected;
      axios
        .post("report/DDC/DiseaseAge", this.payload)
        .then((res: any) => {
          this.data = res.data;
          this.isLoading = false;
        })
        .catch((err) => console.log(err));
    },
    exportData() {
      const arrayIDDisorders: number[] = this.bleedingDisorderSelected.map(
        function (item: BleedingDisorder) {
          return item.id;
        }
      );
      axios
        .post(
          `report/ddc/DiseaseAge/export`,
          {
            diseaseType: arrayIDDisorders,
            year: this.yearSelected,
          },
          { responseType: "blob" }
        )
        .then((res) => {
          const fileName =
            `${i18n.t("reports.ddcreports.pagetitle")} -` + this.title;
          this.downloadExcel(fileName, res.data);
        })
        .catch((err) => console.log(err));
    },
    downloadExcel(fileName: string, data: any) {
      const blob = new Blob([data], { type: "application/xlsx" });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = fileName + ".xlsx";
      link.click();
    },
  },
  created() {
    this.loadFilters();
    this.loadAgeGroup();
  },
});
